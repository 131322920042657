import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import { height as BotHeight } from 'shared/components/BottomBar/sizes';
import generateRender from 'shared/utils/generateRender';

export const BlackOverlay = styled.div`
  ${props => `opacity: ${props.isVisible ? `1` : `0`};`}
  ${props => `display: ${props.isVisible ? `block` : `none`};`}
  z-index: 202;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms ease-out;
`;

export const DefaultPinnedPanelContainer = styled.div`
  width: 21vw;
  z-index: 200;
  display: flex;
  margin-left: 8vw;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
  height: ${({ slides }) => (slides > 1 ? 'calc(100% - 11.5rem)' : 'calc(100% - 10rem)')};

  @media only screen and (min-width: 1200px) and (max-width: 1350px) and (min-height: 900px) and (max-height: 1020px) {
    padding-bottom: ${({ slides }) => (slides > 1 ? 0 : '1.5rem')};
    ${({ slides }) => (slides > 1 ? '' : 'width: 26vw; margin-left: 7vw;')}
  }

  @media only screen and (max-height: 900px) and (min-width: 1930px) {
    width: 18vw;
  }

  @media only screen and (max-height: 880px) {
    width: ${({ slides }) => (slides > 1 ? 21 : 22)}rem;
    margin-left: 9vw;
    margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 1)}rem;

    @media only screen and (max-width: 1250px) {
      width: ${({ slides }) => (slides > 1 ? 17 : 19)}rem;
      margin-left: 8vw;
      margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 1)}rem;
    }
  }

  @media only screen and (max-height: 800px) {
    width: ${({ slides }) => (slides > 1 ? 18 : 19)}rem;
    margin-left: 10vw;
    margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 1)}rem;

    @media only screen and (max-width: 1200px) {
      width: ${({ slides }) => (slides > 1 ? 15 : 17)}rem;
      margin-left: 10vw;
      margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 1)}rem;
    }
  }

  @media only screen and (max-height: 700px) {
    width: ${({ slides }) => (slides > 1 ? 14 : 13)}rem;
    margin-left: ${({ slides }) => (slides > 1 ? 10 : 12)}vw;
    margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 0)}rem;
    padding-bottom: ${({ slides }) => (slides > 1 ? 0 : 1)}rem;
  }

  @media only screen and (max-height: 600px) {
    width: ${({ slides }) => (slides > 1 ? 11 : 13)}rem;
    margin-left: 12vw;
    margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 0)}rem;
  }

  @media only screen and (max-height: 560px) {
    width: ${({ slides }) => (slides > 1 ? 9 : 11)}rem;
    margin-left: 12vw;
    margin-top: ${({ slides }) => (slides > 1 ? -0.2 : 1)}rem;
  }
`;

export const AdmiralPinnedPanelContainer = styled.div`
  width: 250px;
  z-index: 200;
  display: flex;
  margin-left: 8rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: ${({ slides }) => (slides > 1 ? 'calc(93% - 11.5rem)' : 'calc(93% - 10rem)')};

  @media only screen and (min-width: 1500px) {
    margin-left: 9.5rem;
    width: 280px;
  }

  @media only screen and (min-width: 1800px) {
    margin-left: 12rem;
    width: 340px;
  }

  @media only screen and (min-width: 2100px) {
    margin-left: 22rem;
  }

  @media only screen and (max-height: 790px) {
    margin-left: 8.4rem;
    width: ${({ slides }) => (slides > 1 ? '220px' : '240px')};
  }

  @media only screen and (max-height: 650px) {
    width: ${({ slides }) => (slides > 1 ? '200px' : '220px')};
    margin-left: 11vw;
    margin-top: ${({ slides }) => (slides > 1 ? -1.3 : -0.5)}rem;
  }
`;

export const PinnedPanelContainer = generateRender({
  default: DefaultPinnedPanelContainer,
  admes: AdmiralPinnedPanelContainer,
  admiral: AdmiralPinnedPanelContainer,
});

export const MainSceneContainer = styled.div`
  ${({ theme }) => theme}
  padding-bottom: ${({ bottomState }) => (bottomState ? '0px' : BotHeight)};
`;

export const ModalFooterText = styled.p`
  z-index: 100;
  margin-bottom: -24px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: ${theme.palette.common[29]};
`;

