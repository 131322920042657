import React, { Component } from 'react';
import { SceneView } from '@react-navigation/core';
import ImageBW from '../../components/ImageBoundsWrapper';
import Animated from 'animated/lib/targets/react-dom';
/** Screens */
import Screens from 'shared/screens';
import UpdateTerms from '../../screens/UpdateTerms/UpdateTerms';

/** Persistent Components */
import NavigationBar from '../NavigationBar';
import NavigationConstants from 'shared/components/LobbyOverlay/NavigationConstants';
import SideMenu from '../SideMenu';
import BottomBar from '../BottomBar';
import PinnedPanel from '../PinnedPanel';

/** Utility */
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

/** Modal Components */
import { Modal } from 'react-bootstrap';
import modalComponents from '../modals';

/** Notification Components */
import Toast from '../Toast';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import SLobbyOverlay from 'shared/components/LobbyOverlay';
import Utils from 'shared/utils';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import TimestampManagerComponent from '../TimestampManagerComponent';
import audio from 'shared/utils/audio';

/** Tooltip for web */
import Tooltip from '../../components/Tooltip';

/** Animations */
import { BlackOverlay, PinnedPanelContainer, MainSceneContainer, ModalFooterText } from './styledComponents';

const WHITE_LISTED_MOUNT_MODALS = [Screens.CurrencySelector];

const tooltip = tooltipDetails => {
  return (
    <Tooltip
      visible
      style={tooltipDetails.style ? tooltipDetails.style : {}}
      arrowStyle={tooltipDetails.arrowStyle ? tooltipDetails.arrowStyle : {}}
      containerStyle={tooltipDetails.containerStyle ? tooltipDetails.containerStyle : {}}
      tipStyle={tooltipDetails.tipStyle ? tooltipDetails.tipStyle : {}}
      source={tooltipDetails.source ? tooltipDetails.source : null}
      ibw={tooltipDetails.ibw ? tooltipDetails.ibw : null}
      imageStyle={tooltipDetails.gameImageStyle ? tooltipDetails.gameImageStyle : {}}
      titleStyle={tooltipDetails.titleStyle ? tooltipDetails.titleStyle : {}}
      subTitleStyle={tooltipDetails.subTitleStyle ? tooltipDetails.subTitleStyle : {}}
      iconType={tooltipDetails.iconType ? tooltipDetails.iconType : null}
      displayText={tooltipDetails.title}
      displayTextStyle={tooltipDetails.displayTextStyle}
      subTitle={tooltipDetails.subTitle}
      actionHandler={tooltipDetails.actionHandler}
      dividerStyle={tooltipDetails.dividerStyle ? tooltipDetails.dividerStyle : {}}
      animationClassName={tooltipDetails.animationState}
      popUpType={tooltipDetails.popUpType}
      innerStyle={tooltipDetails.innerStyle ?? {}}
      bottom={tooltipDetails.bottom}
      showArrow={tooltipDetails?.showArrow}
    />
  );
};

export default class LobbyOverlay extends Component {
  state = {
    modal: undefined,
    showModal: false,
    isCoinsGlowAnimationStopped: true,
    isBlackLayoutVisible: false,
  };

  startTimedBonusAnimation = () => {
    // starting animations
    this.setState({ isBlackLayoutVisible: true });
    setTimeout(() => {
      this.setState({ isCoinsGlowAnimationStopped: false });
    }, 1000);

    // setting timeouts for stopping them
    setTimeout(() => {
      this.setState({ isCoinsGlowAnimationStopped: true, isBlackLayoutVisible: false });
    }, 1900);
  };

  async componentDidMount() {
    const initialModal = QueryParamProxy.findParam(RouteParamConstants.PARAM_MODAL);
    if (WHITE_LISTED_MOUNT_MODALS.includes(initialModal)) {
      this.setState({ modal: Screens.CurrencySelector });
    }
    QueryParamProxy.addListener(QueryParamProxy.QUERY_PARAM_CHANGE, this.handleParamsChange);
  }

  componentWillUnmount() {
    clearInterval(this.setTimerInterval);
    QueryParamProxy.removeListener(QueryParamProxy.QUERY_PARAM_CHANGE, this.handleParamsChange);
  }

  getModalComponent = modal => {
    if (modal === null || modal === undefined) {
      return false;
    }

    const component = modalComponents[modal];
    if (!component) throw new Error(`Unsupported Modal Type "${modal}"`);
    if (this.state.showModal && !this.state.popUpOpened) {
      if (this.state.modal) {
        audio.playSfx('popUp', true, 1);
      } else {
        audio.playSfx('popUp');
      }
      this.setState({ popUpOpened: true });
    }
    return component;
  };

  handleParamsChange = params => {
    if (params.hasOwnProperty(RouteParamConstants.PARAM_MODAL)) {
      this.setState({
        // IIFE, defines modal on the setState object if it should be changed.
        ...(() => {
          if (params[RouteParamConstants.PARAM_MODAL] !== undefined) {
            return {
              modal: params[RouteParamConstants.PARAM_MODAL],
            };
          } else {
            return {};
          }
        })(),
        // Control whether to hide/show based on new param state
        showModal: params[RouteParamConstants.PARAM_MODAL] === undefined ? false : true,
      });
    }
    if (!Utils.isEmpty(params[RouteParamConstants.PARAM_EXTERNAL_URL])) {
      window.open(params[RouteParamConstants.PARAM_EXTERNAL_URL], '_blank');
      QueryParamProxy.setQueryParams({
        [RouteParamConstants.PARAM_EXTERNAL_URL]: undefined,
      });
    }
  };

  __dismissModalGenerator = (ModalComponent, dismissModal) => {
    return () => {
      // If the modal was opened with a promise, accept it.
      // This is a no-op otherwise.
      audio.onClick();
      if (this.state.popUpOpened) {
        if (this.state.modal) {
          audio.playSfx('popUpReverse', true, 1);
        } else {
          audio.playSfx('popUpReverse');
        }
        this.setState({ popUpOpened: false });
      }

      let modalHandlerResponse = false;

      if (ModalComponent.DismissHandler) modalHandlerResponse = ModalComponent.DismissHandler.call();
      else dismissModal();

      QueryParamProxy.modalPromiseAccept(modalHandlerResponse);
    };
  };

  render() {
    const themeContext = this.context;
    const hasPromotions = !Utils.isEmpty(this.props.lobbyPromotions);
    const promotionCount = this.props.lobbyPromotions?.length;
    return (
      <SLobbyOverlay
        initialModal={this.state.modal}
        {...this.props}
        render={args => {
          let {
            descriptor,
            loading,
            dismissModal,
            notifications,
            navState,
            showTooltip,
            tooltipDetails,
            showCustomModal,
            updatedTerms,
          } = args;
          const ModalComponent = this.getModalComponent(this.state.modal);

          let modalProps = {
            onHide: this.__dismissModalGenerator(ModalComponent, dismissModal),
            size: 'lg',
            'aria-labelledby': 'contained-modal-title-vcenter',
            centered: true,
          };

          if (ModalComponent && ModalComponent.AsModalProps) {
            modalProps = {
              ...modalProps,
              ...ModalComponent.AsModalProps,
            };
          }
          return (
            <>
              <BlackOverlay
                isVisible={
                  (this.state.isBlackLayoutVisible && this.props.animationFeature) ||
                  this.props.isMissionsTutorialFirstStep
                }
              />

              <TimestampManagerComponent />

              {loading && !updatedTerms && (
                <>
                  <ActivityIndicator />
                </>
              )}
              {!loading && !updatedTerms && (
                <>
                  <NavigationBar
                    navigation={this.props.navigation}
                    isCoinsGlowAnimationStopped={this.state.isCoinsGlowAnimationStopped}
                    isCoinsGlowAnimationEnabled={this.props.animationFeature}
                  />
                  {navState !== NavigationConstants.HIDE_BOTTOM && <SideMenu navigation={descriptor.navigation} />}
                  <MainSceneContainer
                    bottomState={NavigationConstants.HIDE_BOTTOM}
                    theme={themeContext.LobbyOverlay.MainSceneContainer}
                    className={'SceneContainer' + (NavigationConstants.HIDE_BOTTOM ? ' hideBottomBar' : '')}
                  >
                    {hasPromotions && navState === NavigationConstants.NO_HIDE && (
                      <PinnedPanelContainer slides={promotionCount}>
                        <PinnedPanel panelList={this.props.lobbyPromotions} navigation={this.props.navigation} />
                      </PinnedPanelContainer>
                    )}
                    <SceneView component={descriptor.getComponent()} navigation={descriptor.navigation} />
                  </MainSceneContainer>
                  <BottomBar
                    navigation={this.props.navigation}
                    startAnimationCallback={this.startTimedBonusAnimation}
                  />
                </>
              )}

              {updatedTerms && (
                <>
                  <UpdateTerms />
                </>
              )}

              {notifications.toasts && notifications.toasts.length > 0 && <Toast data={notifications.toasts[0]} />}
              {!updatedTerms && (
                <Modal show={this.state.showModal} {...modalProps}>
                  <>
                    {!ModalComponent.hideDefaultBackground && (
                      <div
                        className={`ModalBackground${ModalComponent.isTabbed ? ' Tabbed' : ''}${
                          ModalComponent.hideBeforeSelector || modalProps.hideBeforeSelector ? ' hideBefore' : ''
                        }`}
                      >
                        {showCustomModal ? (
                          <ImageBW
                            className="ModalBackgroundAsset"
                            as={Animated.div}
                            source={assetSource(ASSET_KEY.MODAL_BACKER)}
                            ibw={require(asset`modal-backing-9-slice.ibw`)}
                            widthScale={0.5}
                          />
                        ) : (
                          <ImageBW
                            className="ModalBackgroundAsset"
                            as={Animated.div}
                            source={assetSource(ASSET_KEY.MODAL_BACKING_9_SLICE_WEB)}
                            ibw={require(asset`modal-backing-9-slice.ibw`)}
                            widthScale={0.5}
                          />
                        )}
                      </div>
                    )}
                    {!ModalComponent.hideCloseButton && (
                      <div
                        className="ModalCloseButton"
                        onClick={this.__dismissModalGenerator(ModalComponent, dismissModal)}
                      >
                        <ImageBW
                          source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                          ibw={require(asset`close-button.ibw`)}
                          manualAssetScale={3}
                          style={{
                            objectFit: 'contain',
                            height: '100%',
                            width: '100%',
                          }}
                          innerStyle={{
                            cursor: 'pointer',
                          }}
                          alt="Close"
                        />
                      </div>
                    )}
                    <Modal.Body>
                      {ModalComponent && (
                        <ModalComponent
                          dismissModal={this.__dismissModalGenerator(ModalComponent, dismissModal)}
                          navigation={this.props.navigation}
                        />
                      )}
                    </Modal.Body>
                    {ModalComponent.footer && (
                      <ModalFooterText>
                        {typeof ModalComponent.footer === 'function' ? ModalComponent.footer() : ModalComponent.footer}
                      </ModalFooterText>
                    )}
                  </>
                </Modal>
              )}
              {showTooltip && tooltipDetails && tooltip(tooltipDetails)}
            </>
          );
        }}
      />
    );
  }
}

LobbyOverlay.contextType = ThemeContext;

