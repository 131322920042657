import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';
export var apiBaseQuery = createApi({
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 0,
  endpoints: function endpoints() {
    return {};
  }
});
var apiBaseReducerPath = apiBaseQuery.reducerPath,
  apiBaseReducer = apiBaseQuery.reducer,
  apiBaseMiddleware = apiBaseQuery.middleware;
export { apiBaseReducerPath, apiBaseReducer, apiBaseMiddleware };