import Settings from './Settings';
import { connect } from '../../node_modules/react-redux';
import { clearToken as _clearToken } from '../../state/actions/auth';
import { setSoundSettings as _setSoundSettings } from '../../state/actions/playerInfo';
import { clearPrePurchaseStore as _clearPrePurchaseStore } from '../../state/actions/prePurchase';
import { clearSavedGameInfo as _clearSavedGameInfo } from '../../state/actions/game';
import { setFormInfo as _setFormInfo } from '../../state/actions/formInfo';
import { setLastPromoCode as _setLastPromoCode } from '../../state/actions/shopList';
import { removePushNotification as _removePushNotification } from '../../state/actions/notification';
import { clearOnboarding as _clearOnboarding } from '../../state/actions/currentOnboarding';
import { setModal as _setModal } from '../../state/actions/modal';
import { cleanMissions as _cleanMissions } from '../../state/slices/missions/missions';
import { propertiesClearAssets } from '../../state/slices/properties/properties';
import { clearActionCommands as _clearActionCommands } from '../../state/slices/actionCommands/actionCommands';
import { setIsDiveInitialized as _setIsDiveInitialized } from '../../state/actions/load';
import { apiBaseQuery } from '../../state/api/services/rtkQuery/apiBaseQuery';
var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    casinoIdStatus: state.playerInfo.casinoIdStatus,
    apiRootIndex: state.apiRootIndex.value,
    playerId: state.playerInfo.playerId,
    propertyName: state.playerInfo.propertyName,
    soundEffects: state.playerInfo.soundEffects,
    isDevUser: state.playerInfo.isDevUser,
    music: state.playerInfo.music,
    formInfo: state.formInfo,
    fbToken: state.fbToken,
    propertyId: state.playerInfo.propertyId,
    assetsUrls: state.app.assetsUrls,
    environment: state.apiRootIndex.environment,
    lastLoginInfo: state.token.lastLoginInfo
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearToken: function clearToken() {
      dispatch(_clearToken());
    },
    setSoundSettings: function setSoundSettings(value) {
      dispatch(_setSoundSettings(value));
    },
    setFormInfo: function setFormInfo(info) {
      dispatch(_setFormInfo(info));
    },
    setLastPromoCode: function setLastPromoCode(code) {
      dispatch(_setLastPromoCode(code));
    },
    removePushNotification: function removePushNotification(value) {
      dispatch(_removePushNotification(value));
    },
    clearOnboarding: function clearOnboarding() {
      dispatch(_clearOnboarding());
    },
    clearPrePurchaseStore: function clearPrePurchaseStore() {
      dispatch(_clearPrePurchaseStore());
    },
    clearSavedGameInfo: function clearSavedGameInfo() {
      dispatch(_clearSavedGameInfo());
    },
    clearAssets: function clearAssets() {
      dispatch(propertiesClearAssets());
    },
    cleanMissions: function cleanMissions() {
      dispatch(_cleanMissions());
    },
    setModal: function setModal(newModal) {
      dispatch(_setModal(newModal));
    },
    clearActionCommands: function clearActionCommands() {
      dispatch(_clearActionCommands());
    },
    setIsDiveInitialized: function setIsDiveInitialized(value) {
      dispatch(_setIsDiveInitialized(value));
    },
    clearApiQueryState: function clearApiQueryState() {
      dispatch(apiBaseQuery.util.resetApiState());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);