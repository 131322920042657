import React from 'react';
import MessageItem from './MessageItem';
import CustomScrollBar from '../../../../../components/CustomScrollBar';
import './scrollBarStyles.scss';

const MessageList = props => {
  const { messages, navigation, setActiveMessage } = props;
  return (
    <CustomScrollBar
      customClassNames="custom-scrollbar-message-list"
      containerStyle={{
        marginTop: '2rem',
        width: '95%',
        height: '100%',
      }}
      customStyle={{ height: '100%' }}
    >
      {messages
        ?.filter(message => !message?.deleted)
        .map(filteredMessage => (
          <MessageItem
            key={filteredMessage.message_id}
            message={filteredMessage}
            messageDetailsHandle={setActiveMessage}
            navigation={navigation}
            {...props}
          />
        ))}
    </CustomScrollBar>
  );
};

export default MessageList;

