import theme from 'shared/assets/style/theme';
import Utils from 'shared/utils/index';
import { height as NavHeight } from 'shared/components/NavigationBar/sizes';
import responsiveFontSize from '../lib/responsiveFontSize';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const ScrollBar = {
  backgroundColor: theme.palette.primary[10],
  cursor: 'pointer',
  width: '0.5rem',
  borderRadius: '8px',
  position: 'relative',
};

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const defaultTheme = {
  //TODO: Refactor ScrollBar styles
  ScrollBar,
  LimitedTimeOffer: {
    LTOWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '88%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    MainContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      marginBottom: '0.5%',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 1.2,
      fontWeight: 600,
      height: '25%',
    },
    BannerContainer: {
      marginBottom: 0,
      fontWeight: '600',
      marginLeft: '-3%',
    },
    CoinIcon: {
      width: '12%',
      alignSelf: 'center',
      marginRight: '3%',
      marginTop: '0.5%',
    },
    StarIcon: {
      width: '9%',
      alignSelf: 'center',
      marginTop: '0.6%',
    },
    BuyButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    BuyButton: {
      position: 'absolute',
      bottom: '7%',
      transform: 'translateX(-10px)',
    },
    BuyButtonImageStyle: {
      width: windowHeight > 900 && windowWidth < 1300 ? '18vh' : '24vh',
    },
    BuyButtonTextStyle: {
      fontSize: '2.5em',
    },
    ButtonContainer: {
      cursor: 'pointer',
      pointerEvents: 'auto',
      position: 'absolute',
      height: '12%',
      top: '90%',
    },
    BannerContainerText: {
      paddingLeft: '1vmin',
      fontSize: 'calc(14px + 15 * ((100vw - 800px) / 800))',
    },
  },
  ResetPassword: {
    FormInputsFields: {
      background: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    InputLabel: {
      color: theme.palette.common[29],
    },
  },
  NavigationBar: {
    LoyaltyCurrencyWrapperStyle: {
      position: 'absolute',
      right: windowWidth < 1300 ? 'calc(50% - 185px)' : 'calc(50% - 205px)',
    },
    LoyaltyCurrencyWrapperStyleWithoutCard: {
      position: 'absolute',
      right: 'calc(50% - 205px)',
    },
    LoyaltyCurrencyIconStyle: {
      height: 50,
      width: 50,
      top: -12,
      left: -5,
    },
    LoyaltyCurrencyIconStyleWithMissionsbar: {
      marginLeft: windowWidth < 1300 ? 0 : '8px',
    },
    TopBar: {
      height: '5rem',
    },
    BarSection: {
      width: '100%',
      paddingBottom: 0,
      maxWidth: Utils.ieVersion() !== 0 ? 1430 : 1440,
      display: 'flex',
    },
    SplittedLeftButton: {
      fontSize: '24pt',
    },
    SplittedRightButton: {
      fontSize: 30,
      paddingRight: 15,
    },
    LevelLabel: {
      width: '100%',
      fontSize: 20,
    },
    BuyDealText: {
      lineHeight: 2,
      transform: 'translate(0px, -3px)',
      position: 'relative',
      top: '-0.3rem',
    },
  },
  DefaultTooltip: {
    Border: {
      stopColor1: '#d5bd92',
      stopColor2: '#cfab3e',
    },
    Background: {
      stopColor1: '#14012c',
      stopColor2: '#060516',
    },
  },
  Tier: {
    Card: {
      height: 70,
      width: 95,
      position: 'absolute',
      cursor: 'pointer',
      pointerEvents: 'auto',
    },
    TierWrapper: {
      marginRight: windowWidth < 1400 ? '72px !important' : '103px !important',
    },
    CardAnimation: {
      height: 123,
      width: 150,
      left: -40,
      top: -30,
      zIndex: 10,
    },
  },
  OutOfCoin: {
    QuickBuyOfferDetails: {
      padding: ' 0 10px',
      position: 'absolute',
      bottom: '14%',
      left: 0,
      width: '100%',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
    VisitStoreText: {
      fontSize: 24,
    },
  },
  Settings: {
    HeaderImage: {
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderContent: {
      width: ' 100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 5vmin',
    },
    BackArrowContainer: {
      cursor: 'pointer',
      zIndex: 100,
      position: 'absolute',
      height: '2.3rem',
      width: '1.2rem',
      top: '1rem',
      left: '2rem',
    },
    BackgroundLightDivider: {
      backgroundImage: 'none',
    },
    TitleMargin: {
      marginBottom: '0',
    },
    TitleShadow: {
      display: 'flex',
    },
    SignoutButtonText: {
      fontSize: '24px',
    },
  },
  OfferDetail: {
    PurchaseResponseHighlightHeader: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      marginBottom: '10%',
    },
    PurchaseResponseHeader: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      marginBottom: '12%',
      minHeight: '14%',
    },
    RewardCenterPurchaseResponseMessage: {
      marginTop: '0.5em',
      fontSize: '1.4em',
      height: '12%',
    },
    CarouselIndicator: {
      backgroundColor: theme.palette.secondary[41],
      border: `1.5px solid ${theme.palette.secondary[42]}`,
    },
    CarouselIndicatorActive: {
      backgroundColor: theme.palette.secondary[12],
      border: `1.5px solid ${theme.palette.secondary[13]}`,
    },
    CoinsValue: {},
  },
  LinkAccountForm: {
    HeaderImage: {
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderTitleContainer: {
      padding: 0,
      margin: 0,
    },
    HeaderTitle: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '1.8rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '3.7rem',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
    },
    NeedHelpText: {
      top: '1.6rem',
    },
    FormContainer: {
      margin: 20,
      maxHeight: '70vh',
    },
    MemberLinkAccountLoginButtonText: {
      fontSize: '24px',
    },
  },
  RegisterRewardCard: {
    HeaderImage: {
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderTitle: {
      display: 'flex',
      fontSize: '1.9rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      padding: 0,
      margin: 0,
      justifyContent: 'center',
      lineHeight: '3.7rem',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
    },
    FormDescription: {
      textAlign: 'center',
    },
  },
  BonusLinkMessage: {
    LPBonusContainer: {
      width: '100%',
      textAlign: 'center',
      paddingTop: '376px',
      position: 'absolute',
    },
    BoxContainer: {
      height: '477px',
      width: '100%',
      position: 'relative',
    },
    CoinsBonusContainer: {
      width: '100%',
      textAlign: 'center',
      paddingTop: '392px',
      position: 'absolute',
    },
    BonusLinkButton: {
      position: 'relative',
      width: '100%',
      fontSize: '25px',
      lineHeight: '56px',
      fontWeight: 600,
      textShadow: `1px 1px 0px ${theme.palette.secondary[10]}, 1px -1px 0px ${theme.palette.secondary[10]}, -1px 1px 0px ${theme.palette.secondary[10]}, -1px -1px 0px ${theme.palette.secondary[10]}`,
    },
    BonusInfoContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    CoinImage: {
      position: 'relative',
      width: '28px',
      height: '48px',
      marginRight: '5px',
    },
    BonusText: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '-5px',
    },
  },
  DailySpin: {
    DailyBonusHeader: {
      width: '100%',
      top: '95%',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    BottomBar: {
      width: '100%',
      height: '22%',
    },
    BottomBarContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem 0',
      width: '80%',
      height: '100%',
      maxHeight: windowWidth < 1800 ? 170 : 200,
    },
    BottomBarBackgroundWrapperImage: {
      width: '100%',
      height: '100%',
    },
    WheelItems: {
      fontSize: '2.3rem',
      textStroke: `1px ${theme.palette.primary[4]}`,
      WebkitTextStroke: `1px ${theme.palette.primary[4]}`,
    },
    StreakDaysRowNumber: {
      paddingRight: 1,
      paddingBottom: 1,
    },
    StreakDaysRowBigNumber: {
      paddingRight: 3,
      paddingBottom: 1,
    },
    StreakDaysRowValues: {
      marginTop: 0,
      lineHeight: 'inherit',
    },
    MilestoneCoinsValue: {
      marginBottom: 10,
    },
    MilestoneLoyaltyValue: {
      marginBottom: 0,
    },
    MileStoneRowLoyaltyIcon: {
      height: 40,
      width: 40,
    },
    MilestoneCircleValue: {
      marginTop: (windowWidth < 1500) & (windowHeight < 930) ? '1%' : '0%',
      marginLeft: -1,
    },
    RegDaysItemLoyaltyIcon: {
      height: 24,
      width: 24,
    },
    BottomRightCoinImage: {
      marginBottom: 0,
    },
    SpinButtonText: {
      fontSize: '2.8rem',
    },
    BottomBarLeftContainerWithCard: {
      maxWidth: '150px',
    },
    BottomBarLeftContainerNoCard: {
      maxWidth: '150px',
    },
    BottomBarLeftContainerWithCardText: {
      fontSize: '0.9rem',
    },
    BottomBarLeftContainerNoCardText: {
      fontSize: '0.9rem',
    },
    BottomBarBottomText: {
      height: '30px',
      marginBottom: windowHeight < 900 ? -10 : -5,
    },
    DailyWheelCollectButton: {
      paddingBottom: '3%',
    },
  },
  FtueTooltip: {
    TooltipTitleStyle: {
      fontSize: '1.3rem',
      fontWeight: '400',
    },
    TooltipRewardStyle: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    BiggerBetsStyle: {
      fontSize: '1.3rem',
      fontWeight: '400',
    },
  },
  PurchaseFlow: {
    CreditCardDetails: {
      display: 'flex',
    },
    ContactUsButtonTextStyle: {
      fontSize: 24,
    },
    billingProfileSelect: {
      border: `1px solid ${theme.palette.primary[26]}`,
      borderRadius: 5,
      background: 'rgba(255, 255, 255, 0.1)',
      paddingBottom: 6,
    },
    creditCardField: {
      border: `1px solid ${theme.palette.common[5]}`,
      borderRadius: 5,
      background: 'rgba(255, 255, 255, 0.1)',
      paddingBottom: 6,
    },
  },
  ErrorHandlingWrapper: {
    ButtonTextStyle: {
      fontSize: 24,
    },
  },
  CoinItem: {
    CoinAmount: {
      fontSize: '4vmin',
      fontWeight: 600,
    },
    Extra: {
      fontSize: '1.5vmin',
      marginTop: '1vmin',
      fontWeight: 600,
    },
    BuyButtonText: {
      fontSize: '24px',
    },
  },
  ShopScreen: {
    top: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    left: '0px',
    position: 'absolute',
    width: '100%',
    height: '99%',

    ShopTitle: {
      color: theme.palette.common[15],
      fontSize: '32pt',
      fontWeight: 700,
      textAlign: 'center',
    },

    PurchaseTitle: {
      color: theme.palette.common[15],
      fontSize: '28pt',
      fontWeight: 700,
    },

    Spacer: {
      margin: '0.5rem',
    },

    SaleTimer: {
      position: 'absolute',
      top: '10%',
      right: '5%',
      width: '24%',
      height: '11%',
    },

    ShopContainer: {
      flex: 1,
      margin: '1rem',
      marginTop: '0rem',
      marginBottom: '0rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'stretch',

      ShopHeader: {
        display: 'flex',
        marginTop: '6vmin',
        height: '2rem',
      },

      PackagesContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        padding: '6% 5.5rem 0.4rem',

        TabButtonsContainer: {
          position: 'relative',
          zIndex: 1000,
          display: 'flex',
          top: '14%',
          height: '18%',
          marginTop: '-9.5%',

          TabButton: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            outline: 'none',
            width: '50%',
          },
        },

        CoinItem: {
          margin: '0.2rem 0.4rem',
          height: '4rem',
          borderRadius: '1rem',
          objectFit: 'stretch',
        },

        InnerItem: {
          color: theme.palette.common[16],
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',

          Stack: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
            padding: '4px 0',
            lineHeight: '0.8',
          },
          StackLoyaltyIcon: {
            height: 50,
            width: 30,
            objectFit: 'contain',
            alignSelf: 'center',
          },
          StackAmount: {
            paddingTop: '8%',
            fontSize: '0.9rem',
            textAlign: 'center',
          },
        },
        CoinItemContainer: {
          overflowY: 'auto',
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        VIPItemContainer: {
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          overflowY: 'auto',
          overflowX: 'hidden',
          marginLeft: -8,
        },
      },
    },
  },
  MessageDetails: {
    ScrollBar,
    MessageTitle: {
      fontSize: '1.5rem',
      fontWeight: 600,
      textAlign: 'center',
    },
  },
  OfferList: {
    ScrollBar,
  },
  LinkAccount: {
    Description: {
      width: '100%',
      padding: '0 15%',
      fontSize: '12px',
    },
    LinkAccountContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    LinkAccountTitle: {
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 700,
      margin: '0.2em auto',
    },
    AvailableCards: {
      height: '200px',
      display: 'flex',
      flexDirection: 'row',
      margin: '1.5rem 1rem',
    },
    LinkAccountButton: {
      position: 'relative',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
      width: '100%',
      height: '4rem',
    },
    LightDividerUp: {
      display: 'none',
      width: '100%',
    },
    LightDividerDown: {
      display: 'none',
      width: '100%',
      transform: 'rotate(180deg)',
    },
  },
  VIPItem: {
    LogoImage: {
      display: 'none',
    },
    VIPHourTile: {
      width: windowWidth < 1300 ? '35%' : '32%',
      height: '90%',
      marginLeft: windowWidth < 1300 ? '1.1rem' : 0,
    },
    PlusLoyaltyText: {
      fontWeight: 'bold',
    },
    ItemPlusOffer: {
      width: '100%',
    },
    LoyaltyPointsLogo: {
      margin: '0 1em',
    },
  },
  MessagesHeader: {
    Image: {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      height: '3.7rem',
      width: '100%',
      backgroundSize: 'calc(100% + 2px) 100%',
      backgroundImage: `url(${assetSource(ASSET_KEY.FULL_MODAL_HEADER)}`,
    },
  },
  RewardHome: {
    RewardImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundSize: 'cover',
      pointerEvents: 'all',
      cursor: 'pointer',
    },
    RewardHoverOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      transition: '0.1s',
    },
  },
  RewardListItem: {
    RewardImagePlaceholderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      backgroundSize: 'cover',
    },
    RewardHoverOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      transition: '0.1s',
    },
    OfferCost: {
      position: 'relative',
      padding: '0.5em 0',
      textAlign: 'center',
      flex: 0,
      top: '-25.5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    OfferName: {
      color: theme.palette.common[1],
    },
    OfferCategoryName: {
      top: '-17%',
    },
    RewardCardContainer: {
      width: '100%',
      height: '100%',
      zIndex: -1,
    },
    RewardCardImage: {
      position: 'relative',
    },
    OfferNameText: {
      paddingTop: 0,
    },
    LoyaltyPointsText: {
      paddingTop: 0,
    },
  },
  CongratulationsContainer: {
    CongratsSpan: {
      fontSize: '1em',
      color: theme.palette.common[12],
    },
  },

  Wheel: {
    Amount: {
      color: theme.palette.common[2],
      textShadow: 'none',
      paddingLeft: 4,
      marginRight:
        windowWidth < 1400 && window.innerHeight < 800 ? '-310px' : window.innerHeight < 900 ? '-340px' : '-420px',
      fontSize:
        windowWidth < 1400 && window.innerHeight < 800 ? '2rem' : window.innerHeight < 900 ? '2.2rem' : '2.5rem',
      fontWeight: 600,
      width: '150px',
    },
  },
  SmartBanner: {
    LogoButtonStyle: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
  BottomBar: {
    Divider: {
      height: '90%',
      alignSelf: 'flex-end',
      width: 3,
      position: 'relative',
      bottom: 0,
    },
    BottomBarBackground: {
      height: '5rem',
      backgroundImage: `url("${assetSource(ASSET_KEY.WEB_BACKING)}")`,
      bottom: 0,
      alignItems: 'flex-end',
      justifyContent: 'center',
      backgroundPosition: 'center',
      zIndex: 420,
    },
    BottomBarContainer: {
      maxWidth: 1140,
      display: 'flex',
      width: '100vw',
      alignItems: 'flex-end',
    },
    VIPIcon: {
      height: '5.5em',
    },
    RewardCenterIcon: {
      height: '6em',
    },
    WatchAndEarnIcon: {
      height: '4.5em',
    },
    MailIcon: {
      height: '4.5em',
    },
    SlotsIcon: {
      height: '4.5em',
    },
    BarItemIcon: {
      height: '4.5em',
    },
    BarMissionsIcon: {
      height: '4.5em',
    },
    NotificationMail: {
      top: 0,
      left: '1.8em',
    },
    NotificationWatchAndEarn: {
      top: 0,
      left: '2em',
    },
    NotificationMission: {
      top: 0,
      left: '1.3em',
    },
    NotificationEvents: {
      top: windowHeight > 1100 ? '-0.5vh' : windowHeight > 800 ? '-0.7vh' : '-1vh',
      left: '1.5em',
      paddingTop: '0.5rem',
    },
    NotificationText: {
      padding: 0,
    },
    MissionsIconText: {
      height: '0.9em',
      marginTop: 25,
    },
    MissionsIcon: {
      height: '4.5em',
    },
    TimerLabel: {
      position: 'absolute',
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
      paddingLeft: '15%',
      fontWeight: 600,
      fontSize: '0.8em',
      lineHeight: 1,
      fontFeatureSettings: 'tnum',
      bottom: '0.9rem',
      zIndex: 2,
    },
    BarItem: {
      position: 'relative',
      cursor: 'pointer',
      paddingBottom: '1rem',
      margin: 0,
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'flex-end',
    },
    TimerWrapper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1,
      left: 0,
      bottom: 0,
    },
    TimerBackground: {
      position: 'absolute',
      width: '100%',
      bottom: '-0.5em',
    },
    TimerCollectBackground: {
      bottom: '-0.5em',
    },
    RewardCenterBadge: {
      zIndex: 1,
      backgroundColor: theme.palette.common[41],
      position: 'absolute',
      top: '7px',
      left: '29%',
      borderRadius: '50%',
      height: '1.5rem',
      width: '1.5rem',
      aspectRatio: 1,
      display: 'flex',
      justifyContent: 'center',
      border: `1.5px solid ${theme.palette.common[1]}`,
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  StoreDownload: {
    Container: {
      backgroundColor: theme.palette.primary[28],
    },
  },
  GameUnavailable: {
    ButtonText: {
      paddingTop: '5px',
    },
  },
  RewardCenter: {
    RewardCenterMainContentHolder: {
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
      justifyContent: 'space-between',
    },
    Footer: {
      color: theme.palette.common[1],
      textAlign: 'center',
      textShadowColor: theme.palette.common[28],
      textShadowOffset: { width: -1, height: 1 },
      textShadowRadius: 1,
      position: 'absolute',
      top: '91.5%',
      paddingTop: 2,
      fontSize: responsiveFontSize(10, 14),
      width: '100%',
    },
    ActiveTabImage: {
      margin: '0 1em',
    },
    TabImage: {
      margin: '0 1em',
    },
  },
  CreditCardDetails: {
    CheckBoxContainer: {
      cursor: 'pointer',
      display: 'inline-block',
    },
    CheckBoxBackgroundImage: {
      zIndex: 12,
      width: '100%',
      height: '100%',
    },
    BuyNowButton: {
      fontSize: '1.2vw',
    },
    CvvTooltip: {
      top: 0,
      width: '15rem',
      height: '15rem',
    },
    CvvTooltipArrowOver: {
      bottom: -25,
    },
  },
  LobbyOverlay: {
    MainSceneContainer: {
      paddingTop: NavHeight,
    },
  },
  Start: {
    Logo: {
      marginTop: '6%',
    },
    MainContentContainer: {
      marginTop: 30,
    },
    BottomBarFirstLine: {
      marginTop: 0,
    },
    BottomBarFooterText: {
      marginTop: 0,
      marginBottom: 10,
    },
  },
  MissionsProgress: {
    MissionIcon: {
      right: windowWidth < 1400 ? 'calc(50% + 40px)' : 'calc(50% + 35px)',
      top: -7,
      height: windowWidth < 1400 ? 55 : 58,
      width: windowWidth < 1400 ? 83 : 93,
    },
    MissionIconWithoutCard: {
      right: 'calc(50% + 40px)',
      top: -10,
      height: 58,
      width: 93,
    },
    TimeBar: {
      right: windowWidth < 1400 ? 'calc(50% - 70px)' : 'calc(50% - 80px)',
      top: 42,
      height: windowWidth < 1400 ? 25 : 32,
      width: windowWidth < 1400 ? 140 : 160,
      zIndex: -1,
    },
    TimeBarWithoutCard: {
      right: 'calc(50% - 82px)',
      top: 40,
      height: 32,
      width: 180,
      zIndex: -1,
    },
    TimeRemainingText: {
      right: windowWidth < 1400 ? 'calc(50% - 43px)' : 'calc(50% - 55px)',
      fontSize: windowWidth < 1400 ? 16 : 20,
      top: 42,
    },
    TimeRemainingTextWithoutCard: {
      right: 'calc(50% - 50px)',
      top: 40,
    },
    TextProgress: {
      color: 'white',
      fontWeight: 700,
      fontSize: 30,
      textShadow: '0 0px 3px #000',
      top: '-0.2rem',
    },
    TextProgressWithoutCard: {
      left: 60,
      top: '-2%',
    },
  },
  Account: {
    Label: {
      fontWeight: '700 !important',
      cursor: 'pointer',
    },
  },
  SignUp: {
    ErrorMessage: {
      color: 'red',
    },
    FormInputTheme: {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '0.3em',
      border: '1px solid rgba(255,255,255,0.1)',
    },
    MultipleInputInRowWrapper: {
      width: '45%',
    },
    TermsLink: {
      textDecoration: 'underline',
      color: '#a7550e',
      cursor: 'pointer',
      pointerEvents: 'auto',
    },
    NeedHelpLink: {
      color: theme.palette.common[1],
    },
  },
  GameTile: {
    LivePanelItem: {
      height: '50%',
      width: '25%',
    },
  },
  Chest: {
    ChestAnimation: {
      height: 116.78,
      width: 115,
    },
    ChestAnimationEnd: {
      height: 116.78,
      width: 115,
    },
  },
  NotificationModal: {
    HeaderContainer: {
      height: '14%',
      left: 0,
      objectFit: 'contain',
      top: 0,
      width: '100%',
      zIndex: 11,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  Login: {
    ModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '70vh',
      minHeight: '320px',
      width: '100%',
      marginTop: '-1vw',
      marginBottom: '-1vw',
      paddingTop: '10vh',
    },
    LoginButtonText: {
      fontSize: '24px',
    },
    HeaderStyle: {
      height: '0px',
    },
    BodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',
      padding: '0 2vmin',
    },
  },
  VideoAds: {
    CloseText: {
      fontSize: '24px',
    },
    KeepWatchingText: {
      fontSize: '17px',
    },
  },
  WelcomeBonus: {
    CoinIcon: {
      width: '2.8%',
      alignSelf: 'center',
    },
    BonusAmount: {
      textShadow: `2px 2px 4px ${theme.palette.common[0]}`,
      fontWeight: 600,
      marginLeft: '0.4em',
      alignSelf: 'center',
      fontSize: responsiveFontSize(16, 28),
    },
    OfferDetails: {
      padding: '0 10px',
      position: 'absolute',
      bottom: '18.4%',
      left: '-1%',
      width: '100%',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
  },
  TermsModal: {
    SocialErrorText: {
      fontSize: 24,
    },
    ContactUsButtonTextStyle: {
      fontSize: 24,
    },
  },
  VipAccessFlow: {
    VipAccessImage: {
      width: '100%',
    },
    VipAccessMultipleImageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    VipAccessTwoImagesWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40%',
    },
    VipAccessButtonImage: {
      width: 180,
    },
    VipAccessButtonText: {
      width: '90%',
      lineHeight: 1,
    },
    VipAccessRedeemButtonText: {
      width: '90%',
      lineHeight: 1,
    },
  },
  OnboardingLayer: {
    MessageTitleTooltip: {
      textAlign: 'center',
      fontSize: '1.5rem',
      display: 'flex',
      fontWeight: 600,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  TimeRemaining: {
    TimerLabel: {
      color: theme.palette.secondary[14],
      letterSpacing: '0.05rem',
      lineHeight: '4rem',
      fontFeatureSettings: 'tnum',
      fontSize: '1.1rem',
      paddingRight: '1.8rem',
      position: 'absolute',
      left: '0.7rem',
      textAlign: 'right',
      top: '-0.4rem',
      width: '100%',
    },
    VIPTimer: {
      top: '4rem',
    },
  },
  JSModal: {
    ModalBackgroundAsset: {
      widthScale: 0.3,
    },
  },
  DynamicPopupsButtons: {
    ButtonsContainer: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      position: 'absolute',
      bottom: '10%',
    },
    RowWrapperLink: {
      position: 'absolute',
      bottom: '17%',
      paddingInline: '22%',
    },
  },
  TermsModalWrapper: {
    Modal: {
      padding: windowWidth < 700 ? '0 4vmin !important' : '0 20vmin !important',
    },
    NeedHelpLink: {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.common[1],
    },
  },
  MessageItem: {
    Title: {
      fontSize: 26,
      display: 'inline',
    },
    Subtitle: {
      margin: 0,
    },
  },
  Events: {
    TimeLeftText: {
      marginTop: 0,
    },
  },
  BillingProfile: {
    AddDeleteProfileText: {
      fontSize: '2vmin',
    },
  },
  SideBar: {
    MenuContainer: {
      height: 'calc(100vh - 5rem - 5rem)', // 100vh - bottom bar height - top bar height
      maxHeight: '80vh',
      borderBottomRightRadius: '10vh',
      borderTopRightRadius: '10vh',
      border: '5px solid #6b3095',
      background: 'linear-gradient(to bottom, #2b004a 0%, #330058 25%, #50008a 50%, #330058 75%, #2b004a 100%)',
    },
    MenuItem: {
      padding: '0 0 0 4rem',
    },
    ItemButton: {
      zIndex: -1,
      position: 'absolute',
      left: 0,
    },
    MenuItemText: {
      fontSize: '3.2vw',
    },
  },
  Bar: {
    BarContainer: {
      background: 'rgb(33, 30, 28)',
    },
    BarText: {
      alignSelf: 'center',
      fontSize: windowWidth > 1700 ? '20px' : '18px',
      marginLeft: 'auto',
      paddingLeft: 15,
    },
    BarProgress: {
      background: 'linear-gradient(to bottom right, #7560ae 0%, #662d91 60%)',
    },
  },
  CurrencyDisplay: {
    Icons: {
      loyalty: {
        height: 80,
        width: 80,
        position: 'absolute',
        left: -20,
      },
      coins: {
        height: 50,
        width: 50,
        position: 'absolute',
        left: -20,
      },
    },
    CoinsLabel: {
      fontSize: 20,
      paddingLeft: 5,
    },
    LoyaltyLabel: {
      fontSize: 20,
      left: 70,
    },
  },
  ForgotPasswordModal: {
    ModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '70vh',
      width: '100%',
      marginTop: '-1vw',
      marginBottom: '-1vw',
    },
    ModalBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: '0 2vmin',
    },
  },
  UpgradeAccountFlow: {
    InputField: {
      borderBottom: `2px solid ${theme.palette.secondary[0]}`,
    },
  },
  OfferDetailDescription: {
    ButtonIcon: {
      width: '4em',
    },
    ButtonText: {},
  },
  SportsAccessFlow: {
    SportsAccessButtonImage: {
      width: 180,
    },
    SportsAccessRedeemButtonText: {
      width: '90%',
      lineHeight: 1,
    },
  },
};

export default defaultTheme;

