export default {
  API_ROOT: "api.jamul.greentubepro.com",
  SSO_ENABLED: "false",
  TITLE: "Play Jamul Casino",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "Cj15PKr-DFi3skdGXD9oye_esQtiYQt0",
  APPLICATION_TARGET: "jamul",
  APPLICATION_NAME: "Play Jamul Casino",
  GOOGLE_CLIENT_ID: "363036250440-2j49oodh69hb662c88ff6j5p1scpe3b1.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "prod",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://playjamulcasino.onelink.me/sWoF",
  HELP_LINK: "playjamulcasinohelp.zendesk.com",
  SITE_KEY: "",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "true",
  APPLE_STORE_URL: "https://apps.apple.com/us/app/play-jamul-casino/id6530256231",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.greentubepro.jamul",
  SSO_CALLBACK_PATH: undefined,
  DSDK_APP_TOKEN: "74eb795b-d0c7-4555-9aa8-619eea04c761",
  DSDK_HASH_KEY: "8617A4F19B94E09E15DB5",
  DSDK_SV_URL: "https://a.bluebat.dive.games/jamul",
  DSDK_API_URL: "https://api.bluebat.dive.games",
  DSDK_DOMAIN: "www.playjamulcasino.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "playjamulcasino.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "10",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://playjamulcasinohelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "true",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "true",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "BUTTON",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: "0190eb1f-9ce8-7e72-925a-9dbbec093eb2",
  FACEBOOK_LOGIN: "false"
};